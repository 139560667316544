"use client";
import { useAtom } from "jotai";
import React, { useEffect } from "react";
import Snackbar from "@/components/common/Snackbar";
import { Analytics } from "@vercel/analytics/react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { darkModeState } from "@/jotai";
import ConfirmDialog from "@/components/common/ConfirmDialog";
import { Login } from "@/components/layout/Login";
import { ImageUploader } from "@/components/home/logined/ImageUploader";
import { Loading } from "../common/Loading";
// import { DevTools } from "jotai-devtools";
export const MuiThemeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [darkMode, setDarkMode] = useAtom(darkModeState);
  const themeOption = {
    palette: {
      // @ts-ignore
      mode: darkMode ? "dark" : "light",
      // 기타 테마 설정
    },
    typography: {
      fontFamily: "inherit",
    },
  };
  // @ts-ignore
  const darkTheme = createTheme(themeOption);
  return <ThemeProvider theme={darkTheme}>{children}</ThemeProvider>;
};
