import React from "react";
import Snackbar from "@/components/common/Snackbar";
import { Analytics } from "@vercel/analytics/react";
import ConfirmDialog from "@/components/common/ConfirmDialog";
import { Login } from "@/components/layout/Login";
import { ImageUploader } from "@/components/home/logined/ImageUploader";
import { Loading } from "@/components/common/Loading";
export default function GlobalUI() {
  return (
    <>
      <Snackbar />
      <Loading />
      {/* <Analytics /> */}
      <ConfirmDialog />
      <Login></Login>
      <ImageUploader />
    </>
  );
}
