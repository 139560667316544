import { useAtom } from "jotai";
import { snackbarState } from "@/jotai";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Snackbar as SnackbarUI } from "@mui/material";
export default function Snackbar() {
  const [snackbar, setSnackbar] = useAtom(snackbarState);
  const handleClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };
  return (
    <SnackbarUI
      open={snackbar.open}
      message={snackbar.message}
      onClose={handleClose}
      autoHideDuration={snackbar.autoHideDuration}
      anchorOrigin={{
        vertical: snackbar.vertical,
        horizontal: snackbar.horizontal,
      }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    ></SnackbarUI>
  );
}
