"use client";
import { useEffect, useState } from "react";
import { darkModeState, loadingState } from "@/jotai";
import { CircularProgress } from "@mui/material";
import { useAtomValue } from "jotai";

export function Loading() {
  const darkMode = useAtomValue(darkModeState);
  const loading = useAtomValue(loadingState);
  const [visible, setVisible] = useState(loading);

  useEffect(() => {
    if (!loading) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  }, [loading]);

  if (!visible) {
    return null;
  }

  return (
    <div
      className="fixed top-0 left-0 h-screen w-screen flex justify-center items-center"
      style={{ opacity: loading ? 1 : 0, transition: "opacity 0.5s" }}
    >
      <CircularProgress sx={{ color: darkMode ? "white" : "black" }} />
    </div>
  );
}
