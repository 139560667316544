"use client"
import { MuiThemeProvider } from "@/components/core/MuiThemeProvider";
import { Provider as JotaiProvider, useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import GlobalUI from "@/components/core/GlobalUI";
import "@/app/globals.css";
import "@/app/globals.mui.css";
import { WebViewCommunicator } from "@/components/core/WebViewCommunicator";
import { AuthStateChangeAction } from "../../components/core/AuthStateChangeAction";
import { useLastTabSurvive } from "@/functions/hooks/useLastTabSurvive";

export default function Layout({ children }: { children: React.ReactNode }) {
    useLastTabSurvive();
    const [visible, setVisible] = useState(false);
    useEffect(()=>{
        setVisible(true);
    },[])
    return (
        <div className={`${visible ? 'visible' : 'hidden'}`} style={{ animationName: 'fadeIn', animationDuration: '1s' }}>
            <JotaiProvider>
                <MuiThemeProvider>
                    {children}
                    <GlobalUI></GlobalUI>
                    <WebViewCommunicator />
                    <AuthStateChangeAction />
                </MuiThemeProvider>
            </JotaiProvider>
        </div>);
}
