import React, { useEffect } from "react";
import { useAtom } from "jotai";
import { confirmState, closeConfirmState } from "@/jotai"; // 경로는 프로젝트 설정에 맞게 조정하세요.
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import s from "./index.module.css";

export default function ConfirmDialog() {
  const [confirm] = useAtom(confirmState);
  const [, closeConfirm] = useAtom(closeConfirmState);

  const handleYes = () => {
    if (!confirm.onYes) return;
    confirm.onYes();
    closeConfirm();
  };

  const handleNo = () => {
    if (!confirm.onNo) return;
    confirm.onNo();
    closeConfirm();
  };

  useEffect(() => {
    // @ts-ignore
    const handleKeyDown = (event) => {
      if (event.keyCode === 13 && confirm.open) {
        handleYes();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [confirm.open]);

  return (
    <Dialog
      open={confirm.open}
      onClose={handleNo}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">
          <div
            className={s.content}
            dangerouslySetInnerHTML={{
              __html: confirm.message,
            }}
          ></div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {confirm.onNo && (
          <Button onClick={handleNo} color="secondary" autoFocus>
            {confirm.noLabel ? confirm.noLabel : "취소"}
          </Button>
        )}
        {confirm.onYes && (
          <Button onClick={handleYes} color="primary">
            {confirm.yesLabel ? confirm.yesLabel : "동의"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
