"use client";
import { useEffect, useRef, useState } from "react";
import * as LR from "@uploadcare/blocks";
import {
  openSnackbarState,
  refreshSeedAfterContentUpdate,
  runSyncState,
} from "@/jotai";
import { useSetAtom } from "jotai";
import "../../../../public/lib/uploadcare/local.css";
import { create, loginMode, update } from "@/watermelondb/control/Page";
import { extractBodyInfo } from "@/functions/extractBodyInfo";
import { getUserType } from "@/functions/getUserType";
import { createClient } from "@/supabase/utils/client";
LR.registerBlocks(LR);

function removePreviewFromCdnUrl(cdnUrl: string): string {
  const regex = /-\/preview\/?(\d+x\d+)?\/?$/;
  const match = cdnUrl.match(regex);
  if (match) {
    return cdnUrl.replace(match[0], "").replace(/\/?$/, "/");
  }
  return cdnUrl;
}

export function ImageUploader() {
  const instant_provider = useRef(null);
  const instant_config_ref = useRef(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const setLastUpdateContentId = useSetAtom(refreshSeedAfterContentUpdate);
  const openSnackbar = useSetAtom(openSnackbarState);
  const runSync = useSetAtom(runSyncState);

  useEffect(() => {
    const supabase = createClient();
    supabase.auth.getSession().then((session) => {
      if (session.data.session) {
        if (instant_config_ref.current) {
          // @ts-ignore
          instant_config_ref.current.metadata = {
            user_id: session.data.session.user.id,
          };
        }
      }
    });
  }, []);
  useEffect(() => {
    // @ts-ignore
    const handleUploadFinish = (e) => {
      if (e.detail.ctx !== "instant-uploader") {
        return;
      }
      setUploadedFiles(e.detail.data);
    };
    // @ts-ignore
    const handleCloudModification = (e) => {
      if (e.detail.ctx !== "instant-uploader") {
        return;
      }
      setUploadedFiles(
        // @ts-ignore
        uploadedFiles.map((item) => {
          // @ts-ignore
          if (item.uuid === e.detail.data.uuid) {
            // @ts-ignore
            return { ...item, cdnUrl: e.detail.data.cdnUrl };
          }
          return item;
        }),
      );
    };
    // @ts-ignore
    const handleDoneFlow = async (e) => {
      if (e.detail.ctx !== "instant-uploader") {
        return;
      }
      if (uploadedFiles.length === 0) {
        return;
      }
      const files = uploadedFiles
        .map((item) => {
          const SCREEN_SIZE = 734;
          const DEFAULT_SIZE = SCREEN_SIZE; // 적절한 기본값 설정

          // @ts-ignore
          const width = Number(item?.contentInfo?.image?.width) || DEFAULT_SIZE;
          const maxWidth = Math.min(
            SCREEN_SIZE,
            isNaN(width) ? DEFAULT_SIZE : width,
          );

          const height =
            // @ts-ignore
            Number(item?.contentInfo?.image?.height) || DEFAULT_SIZE; // @ts-ignore
          const maxHeight = Math.min(
            SCREEN_SIZE,
            isNaN(height) ? DEFAULT_SIZE : height,
          );

          return `<figure class="outset-both" style="max-width:${maxWidth}px;"><img src="${removePreviewFromCdnUrl(
            // @ts-ignore
            item.cdnUrl,
          )}-/preview/${maxWidth}x${maxHeight}/-/format/auto/-/quality/smart/" width="${maxWidth}" height="${maxHeight}"/></figure>`;
        })
        .join("\n");

      const { length, img_url } = extractBodyInfo(files);
      const userType = getUserType();
      const data = await create(
        {
          title: "제목 짓는 중...",
          body: files,
          is_public: false,
          length,
          img_url,
        },
        loginMode(userType),
      ).then((result) => {
        // @ts-ignore
        setLastUpdateContentId(Math.random());
        fetchCaption(
          // @ts-ignore
          result.id,
          // @ts-ignore
          `${uploadedFiles[0].cdnUrl}-/preview/720x720/`,
        )
          .then(async (titleResult) => {
            await update(
              {
                id: result.id,
                title: titleResult.result,
                body: files,
                is_public: false,
                length,
                img_url,
              },
              loginMode(userType),
            );
            setLastUpdateContentId(Math.random());
            runSync({});
          })
          .catch((error) => {
            console.error("🚀 ~ handleDoneFlow ~ error:", error);
            openSnackbar({
              message: "자동 제목을 짓지 못했습니다. 제목을 지어주세요.",
            });
            // 여기에 에러 처리 로직을 추가합니다.
          });
        // @ts-ignore
      });
      // @ts-ignore
      instant_provider.current.uploadCollection.clearAll();
    };

    window.addEventListener("LR_UPLOAD_FINISH", handleUploadFinish);
    window.addEventListener("LR_CLOUD_MODIFICATION", handleCloudModification);
    window.addEventListener("LR_DONE_FLOW", handleDoneFlow);

    return () => {
      window.removeEventListener("LR_UPLOAD_FINISH", handleUploadFinish);
      window.removeEventListener(
        "LR_CLOUD_MODIFICATION",
        handleCloudModification,
      );
      window.removeEventListener("LR_DONE_FLOW", handleDoneFlow);
    };
  }, [uploadedFiles]);
  return (
    <div id="uploaderer">
      <div className="fixed bottom-0 right-0 invisible">
        {/* @ts-ignore */}
        <lr-config
          id="instant-uploader-config"
          ctx-name="instant-uploader"
          multiple="true"
          pubkey={process.env.NEXT_PUBLIC_UPLOADCARE_PUBLIC_KEY}
          source-list="local, camera"
          ref={instant_config_ref}
        />
        {/* @ts-ignore */}
        <lr-upload-ctx-provider
          id="uploaderctx"
          ctx-name="instant-uploader"
          ref={instant_provider}
        />
        {/* @ts-ignore */}
        <lr-file-uploader-regular
          ctx-name="instant-uploader"
          className="l10n-ko-KR"
          css-src="https://cdn.jsdelivr.net/npm/@uploadcare/blocks@0.27.4/web/lr-file-uploader-regular.min.css"
        />
      </div>
    </div>
  );
}

async function fetchCaption(id: number, imageUrl: string) {
  try {
    const response = await fetch("/api/ai/captioning", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id, image_url: imageUrl }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw error;
  }
}
