"use client";
import React from "react";
import { usePathname, useRouter } from "next/navigation";
import { getUserType } from "../getUserType";
import { syncLogger } from "@/debug/sync";

export function useLastTabSurvive() {
    React.useEffect(() => {
        if(getUserType() === 'named')
            return ()=>{};
        const channel = new BroadcastChannel("last-tab");
        channel.postMessage({ type: "active" });
        syncLogger('send', { type: "active" })
        channel.onmessage = (e) => {
            syncLogger("receive", e.data);
            if (e.data.type === "active") {
                channel.postMessage({ type: "leave" });
            }
            if (e.data.type === "leave") {
                location.href = '/bye';
            }
        };
        return () => {
            syncLogger('send', { type: "close"})
            channel.postMessage({ type: "close" });
            channel.close();
        };
    }, []);
}
