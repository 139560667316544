"use client";
import { darkModeState, loginOpenState, openConfirmState } from "@/jotai";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import Image from "next/image";
import { CircularProgress } from "@mui/material";
import { useRouter } from "next/navigation";
import { setUserType } from "@/functions/getUserType";
import { createClient } from "@/supabase/utils/client";
import { clearStorage } from "@/functions/clearStorage";
import { authLogger } from "@/debug/auth";
const getURL = () => {
  let url =
    process?.env?.NEXT_PUBLIC_SOCIAL_LOGIN_REDIRECT_TO ??
    process?.env?.NEXT_PUBLIC_VERCEL_URL ??
    (typeof window !== "undefined"
      ? window.location.origin
      : "http://localhost:3000/");

  // Make sure to include `https://` when not localhost.
  url = url.includes("http") ? url : `https://${url}`;

  // Make sure to include a trailing `/`.
  url = url.charAt(url.length - 1) === "/" ? url : `${url}/`;
  return url;
};
export function Login({}) {
  const [loginOpen, setLoginOpen] = useAtom(loginOpenState);
  const darkMode = useAtomValue(darkModeState);
  const [loading, setLoading] = useState(false);
  const openConfirm = useSetAtom(openConfirmState);
  const router = useRouter();
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setLoginOpen({ open: false });
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (loginOpen) {
      setLoading(false);
    }
  }, [loginOpen]);

  function getRedirectURL() {
    if (loginOpen.mode === "switchOnlineUser") {
      return getURL() + "auth/callback?redirect=/migration";
    } else {
      return getURL() + "auth/callback?redirect=/home";
    }
  }

  function beforeLogin() {
    if (loginOpen.mode !== "switchOnlineUser") {
      authLogger("clear storage before login");
      clearStorage();
    }
    setLoading(true);
  }

  async function signInWithGitHub() {
    beforeLogin();
    const supabase = createClient();
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: "github",
      options: { redirectTo: getRedirectURL() },
    });
  }

  async function signInWithGoogle() {
    if (isInAppBrowser()) {
      openConfirm({
        message:
          "구글을 이용한 로그인은 현재 앱에서는 지원하지 않습니다. 브라우저나 다른 로그인 방법을 이용해 주세요",
        onYes: () => {},
        yesLabel: "확인",
      });
    } else {
      beforeLogin();
      const supabase = createClient();
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: "google",
        options: { redirectTo: getRedirectURL() },
      });
    }
  }
  async function signInWithApple() {
    beforeLogin();
    const supabase = createClient();
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: "apple",
      options: { redirectTo: getRedirectURL() },
    });
  }
  async function signInWithoutSignUp() {
    beforeLogin();
    setUserType("anonymous");
    location.href = "/home";
  }
  async function signInWithEmail() {
    beforeLogin();
    router.push("/login");
  }
  return (
    <div
      className={`flex flex-col h-screen w-screen justify-center fixed z-100 top-0 left-0 bg-black/80 dark:bg-white/30 backdrop-blur-md ${
        loginOpen.open ? "" : "hidden"
      }`}
      onClick={(event: React.MouseEvent) => {
        setLoginOpen({ open: false });
      }}
    >
      <div className="flex flex-col gap-6 p-12 rounded-md items-center">
        {loading ? (
          <p className="flex justify-center">
            {" "}
            <CircularProgress sx={{ color: darkMode ? "white" : "black" }} />
          </p>
        ) : (
          <>
            {loginOpen.mode === "switchOnlineUser" ? (
              <div className="p-1 text-center text-white dark:text-black"></div>
            ) : null}
            <LoginButton
              logo={`/signin/apple_logo${darkMode ? "_dark" : ""}.svg`}
              label="Apple로 로그인"
              onClick={(event) => {
                event.stopPropagation();
                signInWithApple();
              }}
            />
            <LoginButton
              logo={`/signin/google_logo.svg`}
              label="Google로 로그인"
              onClick={(event) => {
                event.stopPropagation();
                signInWithGoogle();
              }}
            />
            <LoginButton
              logo={`/signin/github_logo${darkMode ? "_dark" : ""}.svg`}
              label="Github로 로그인"
              onClick={(event) => {
                event.stopPropagation();
                signInWithGitHub();
              }}
            />

            {/* dev */}
            {process.env.NODE_ENV === "development" && (
              <LoginButton
                logo={`/signin/email_logo${darkMode ? "_dark" : ""}.svg`}
                label="이메일로 로그인"
                onClick={(event) => {
                  signInWithEmail();
                }}
              />
            )}
            {loginOpen.mode !== "switchOnlineUser" && (
              <LoginButton
                logo={`/signin/disconnected_user${darkMode ? "_dark" : ""}.svg`}
                label="회원가입 없이 이용"
                onClick={(event) => {
                  event.stopPropagation();
                  signInWithoutSignUp();
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

type LoginButtonProps = {
  logo: string;
  label: string;
  onClick: (event: React.MouseEvent) => void;
};
function LoginButton({ logo, label, onClick }: LoginButtonProps) {
  return (
    <div
      className="flex justify-center items-center h-[56pt] bg-white dark:bg-black w-full max-w-[423px] rounded-md cursor-pointer drop-shadow-lg"
      onClick={onClick}
    >
      <Image
        className="w-[16pt] sm:w-[20pt]"
        width="27"
        height="20"
        src={logo}
        alt={"apple logo"}
      />
      <div className="text-[16pt] sm:text-[20pt] text-black dark:text-white px-3">
        {label}
      </div>
    </div>
  );
}

function isInAppBrowser() {
  if (typeof window === "undefined") {
    return false;
  }
  // 인앱 브라우저를 식별할 수 있는 키워드 목록
  const inAppIdentifiers = [
    "FBAN",
    "FBAV",
    "Twitter",
    "Line",
    "NAVER",
    "Instagram",
    "Pinterest",
    "Snapchat",
    "inapp",
  ];

  // 주어진 사용자 에이전트를 소문자로 변환하여 대소문자 구분 없이 비교
  const userAgentLower = window.navigator.userAgent.toLowerCase();

  // 인앱 식별자 중 하나라도 사용자 에이전트에 포함되어 있는지 확인
  return inAppIdentifiers.some((identifier) =>
    userAgentLower.includes(identifier.toLowerCase()),
  );
}
